const routes = [
    {
        path: "Orders",
        name: "consumer:orders",
        //alias: "/Orders",
        component: () =>
        import(
            /* webpackChunkName: "orderListing" */ "@/modules/orderlisting/OrderListing.vue"
        ),
        props: true,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "Order/:orderUniqueId/invitation",
        name: "consumer:orderinvitation",
        //alias: "/Orders",
        component: () =>
        import(
            /* webpackChunkName: "order" */ "@/modules/order/Order.vue"
        ),
        props: true,
        meta: {
            requiresAuth: true,
            tabIndex: 0
        }
    },
    {
        path: "Order/:orderUniqueId/milestone",
        name: "consumer:ordermilestone",
        //alias: "/Orders",
        component: () =>
        import(
            /* webpackChunkName: "order" */ "@/modules/order/Order.vue"
        ),
        props: true,
        meta: {
            requiresAuth: true,
            tabIndex: 3
        }
    },
    {
        path: "Order/:orderUniqueId/:documentId/document",
        name: "consumer:orderdocuments",
        //alias: "/Orders",
        component: () =>
        import(
            /* webpackChunkName: "order" */ "@/modules/order/Order.vue"
        ),
        props: true,
        meta: {
            requiresAuth: true,
            tabIndex: 1
        }
    },
    {
        path: "Order/:orderUniqueId",
        name: "consumer:order",
        //alias: "/Orders",
        component: () =>
        import(
            /* webpackChunkName: "order" */ "@/modules/order/Order.vue"
        ),
        props: true,
        meta: {
            requiresAuth: true,
            tabIndex: 0
        }
    },
    {
        path: "Profile/:uniqueId",
        name: "consumer:profile",
        //alias: "/Orders",
        component: () =>
        import(
            /* webpackChunkName: "profileManagement" */ "@/modules/consumer/profilemanagement/ProfileManagement.vue"
        ),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "Help/",
        name: "consumer:help",
        //alias: "/Orders",
        component: () =>
            import(
            /* webpackChunkName: "help" */ "@/modules/consumer/help/Help.vue"
            ),
        meta: {
            requiresAuth: true
        }
    },
];

export default routes;